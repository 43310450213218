import SelfInsuredQuestionList, {
  SelfInsuredQuestion,
} from "./SelfInsuredPageQuestions";
import withEmployerExemptionsApplication, {
  WithEmployerExemptionsApplicationProps,
} from "src/hoc/withEmployerExemptionsApplication";

import FormLabel from "src/components/core/FormLabel";
import PageNotFound from "src/components/PageNotFound";
import QuestionPage from "src/components/QuestionPage";
import React from "react";
import { Trans } from "react-i18next";
import { isFeatureEnabled } from "src/services/featureFlags";
import useFormState from "src/hooks/useFormState";
import useFunctionalInputProps from "src/hooks/useFunctionalInputProps";
import { useTranslation } from "src/locales/i18n";

/* 
prefix each field 'exemptionRequest.' this is needed for the employer 
exemptions progress page to display correctly
*/
export const fields = [
  "exemptionRequest.self_insured.questions.does_plan_provide_enough_bonding_leave",
  "exemptionRequest.self_insured.questions.does_plan_provide_enough_medical_leave",
  "exemptionRequest.self_insured.questions.does_plan_provide_enough_caring_leave",
  "exemptionRequest.self_insured.questions.does_plan_provide_enough_armed_forces_leave",
];

const translationPrefix =
  "pages.employersExemptionsSelfInsuredLeaveOptionsDetails";

export const EmployerExemptionsSelfInsuredLeaveOptionsDetails = (
  props: WithEmployerExemptionsApplicationProps
) => {
  const { appLogic, exemptionRequest, user } = props;
  const { t } = useTranslation();
  const { user_leave_administrators } = user;

  const { formState, updateFields } = useFormState({
    self_insured: {
      questions: {
        does_plan_provide_enough_bonding_leave:
          exemptionRequest.self_insured?.questions
            ?.does_plan_provide_enough_bonding_leave,
        does_plan_provide_enough_medical_leave:
          exemptionRequest.self_insured?.questions
            ?.does_plan_provide_enough_medical_leave,
        does_plan_provide_enough_caring_leave:
          exemptionRequest.self_insured?.questions
            ?.does_plan_provide_enough_caring_leave,
        does_plan_provide_enough_armed_forces_leave:
          exemptionRequest.self_insured?.questions
            ?.does_plan_provide_enough_armed_forces_leave,
      },
    },
  });

  // TODO (PFMLPB-19806): removal of feature flag
  const isEnableEmployerExemptionsPortal = isFeatureEnabled(
    "enableEmployerExemptionsPortal"
  );

  const handleSave = async () => {
    await appLogic.employerExemptionsApplication.update(
      exemptionRequest.employer_exemption_application_id,
      formState
    );
  };

  const getFunctionalInputProps = useFunctionalInputProps({
    errors: appLogic.errors,
    formState,
    updateFields,
  });

  if (!isEnableEmployerExemptionsPortal) {
    return <PageNotFound />;
  }

  const doesPlanProvidEnoughBondingLeaveQuestion: SelfInsuredQuestion = {
    label: `${translationPrefix}.doesPlanProvideEnoughBondingLeaveQuestion`,
    isVisible: exemptionRequest.has_family_exemption === true,
    inputProps: getFunctionalInputProps(
      "self_insured.questions.does_plan_provide_enough_bonding_leave"
    ),
  };

  const doesPlanProvideEnoughMedicalLeaveQuestion: SelfInsuredQuestion = {
    label: `${translationPrefix}.doesPlanProvideEnoughMedicalLeaveQuestion`,
    isVisible: exemptionRequest.has_medical_exemption === true,
    inputProps: getFunctionalInputProps(
      "self_insured.questions.does_plan_provide_enough_medical_leave"
    ),
  };

  const doesPlanProvideEnoughCaringLeaveQuestion: SelfInsuredQuestion = {
    label: `${translationPrefix}.doesPlanProvideEnoughCaringLeaveQuestion`,
    isVisible: exemptionRequest.has_family_exemption === true,
    inputProps: getFunctionalInputProps(
      "self_insured.questions.does_plan_provide_enough_caring_leave"
    ),
  };

  const doesPlanProvideEnoughArmedForcesLeaveQuestion: SelfInsuredQuestion = {
    label: `${translationPrefix}.doesPlanProvideEnoughArmedForcesLeaveQuestion`,
    isVisible: exemptionRequest.has_family_exemption === true,
    inputProps: getFunctionalInputProps(
      "self_insured.questions.does_plan_provide_enough_armed_forces_leave"
    ),
  };

  const questions: SelfInsuredQuestion[] = [
    doesPlanProvidEnoughBondingLeaveQuestion,
    doesPlanProvideEnoughMedicalLeaveQuestion,
    doesPlanProvideEnoughCaringLeaveQuestion,
    doesPlanProvideEnoughArmedForcesLeaveQuestion,
  ];

  return (
    <React.Fragment>
      <QuestionPage
        title={t(`${translationPrefix}.title`)}
        headingPrefix={
          <Trans
            i18nKey="shared.employerExemptions.employerEIN"
            values={{
              employer_fein: user_leave_administrators[0].employer_fein,
            }}
          />
        }
        onSave={handleSave}
      >
        <FormLabel component="legend">
          {t(`${translationPrefix}.sectionLabel`)}
        </FormLabel>
        <SelfInsuredQuestionList
          labelTranslationPrefix={translationPrefix}
          questions={questions}
        />
      </QuestionPage>
    </React.Fragment>
  );
};

export default withEmployerExemptionsApplication(
  EmployerExemptionsSelfInsuredLeaveOptionsDetails
);
