import withEmployerExemptionsApplication, {
  WithEmployerExemptionsApplicationProps,
} from "src/hoc/withEmployerExemptionsApplication";

import FormLabel from "src/components/core/FormLabel";
import PageNotFound from "src/components/PageNotFound";
import QuestionPage from "src/components/QuestionPage";
import React from "react";
import { Trans } from "react-i18next";
import { isFeatureEnabled } from "src/services/featureFlags";
import useFormState from "src/hooks/useFormState";
// TODO (PFMLPB-21868): ENG FE: Exemptions FE: self-insured 4 of 5 design and content
// uncomment useFunctionalInputProps as part of PFMLPB-21868
// import useFunctionalInputProps from "src/hooks/useFunctionalInputProps";
import { useTranslation } from "src/locales/i18n";

/* 
prefix each field 'exemptionRequest.' this is needed for the employer 
exemptions progress page to display correctly
*/
export const fields = [
  /* 
    TODO (PFMLPB-21868) Uncomment these once input UI is implemented, as leaving them in without the ability to set values blocks
    the ability for the Save & Continue Button to route to the next page
    */
  //   "exemptionRequest.self_insured.questions.does_plan_provide_pfml_job_protection",
  //   "exemptionRequest.self_insured.questions.does_plan_provide_return_to_work_benefits",
  //   "exemptionRequest.self_insured.questions.does_plan_cover_employee_contribution",
  //   "exemptionRequest.self_insured.questions.does_plan_favor_paid_leave_benefits",
];

export const EmployerExemptionsSelfInsuredWorkplacePolicyDetails = (
  props: WithEmployerExemptionsApplicationProps
) => {
  const { appLogic, exemptionRequest, user } = props;
  const { t } = useTranslation();
  const { user_leave_administrators } = user;

  // TODO (PFMLPB-21868): ENG FE: Exemptions FE: self-insured 4 of 5 design and content
  // uncomment updateFields as part of PFMLPB-21868
  const { formState /*, updateFields */ } = useFormState({
    self_insured: {
      questions: {
        does_plan_provide_pfml_job_protection:
          exemptionRequest.self_insured?.questions
            ?.does_plan_provide_pfml_job_protection,
        does_plan_provide_return_to_work_benefits:
          exemptionRequest.self_insured?.questions
            ?.does_plan_provide_return_to_work_benefits,
        does_plan_cover_employee_contribution:
          exemptionRequest.self_insured?.questions
            ?.does_plan_cover_employee_contribution,
        does_plan_favor_paid_leave_benefits:
          exemptionRequest.self_insured?.questions
            ?.does_plan_favor_paid_leave_benefits,
      },
    },
  });

  // TODO (PFMLPB-19806): removal of feature flag
  const isEnableEmployerExemptionsPortal = isFeatureEnabled(
    "enableEmployerExemptionsPortal"
  );

  const handleSave = async () => {
    await appLogic.employerExemptionsApplication.update(
      exemptionRequest.employer_exemption_application_id,
      formState
    );
  };

  // TODO (PFMLPB-21868): ENG FE: Exemptions FE: self-insured 4 of 5 design and content
  // uncomment getFunctionalInputProps as part of PFMLPB-21868
  // const getFunctionalInputProps = useFunctionalInputProps({
  //     errors: appLogic.errors,
  //     formState,
  //     updateFields,
  // });

  if (!isEnableEmployerExemptionsPortal) {
    return <PageNotFound />;
  }

  return (
    <React.Fragment>
      <QuestionPage
        title={t(
          "pages.employersExemptionsSelfInsuredWorkplacePolicyDetails.title"
        )}
        headingPrefix={
          <Trans
            i18nKey="shared.employerExemptions.employerEIN"
            values={{
              employer_fein: user_leave_administrators[0].employer_fein,
            }}
          />
        }
        onSave={handleSave}
      >
        <FormLabel component="legend">
          {t(
            "pages.employersExemptionsSelfInsuredWorkplacePolicyDetails.sectionLabel"
          )}
        </FormLabel>
      </QuestionPage>
    </React.Fragment>
  );
};

export default withEmployerExemptionsApplication(
  EmployerExemptionsSelfInsuredWorkplacePolicyDetails
);
