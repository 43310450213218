import InputChoiceGroup from "src/components/core/InputChoiceGroup";
import React from "react";
import { useTranslation } from "src/locales/i18n";

export interface SelfInsuredQuestion {
  label: string;
  isVisible?: boolean;
  inputProps: {
    errorMsg: JSX.Element | undefined;
    name: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    value: unknown;
  };
}

interface SelfInsuredQuestionProps {
  labelTranslationPrefix: string;
  questions: SelfInsuredQuestion[];
}

const SelfInsuredQuestionList: React.FC<SelfInsuredQuestionProps> = ({
  labelTranslationPrefix,
  questions,
}) => {
  const { t } = useTranslation();

  const questionList: React.JSX.Element[] = questions?.reduce(
    (arr, question) => {
      if (question.isVisible || question.isVisible === undefined) {
        arr.push(
          <InputChoiceGroup
            {...question.inputProps}
            smallLabel
            key={question.inputProps.name}
            choices={[
              {
                checked:
                  typeof question.inputProps.value === "boolean" &&
                  question.inputProps.value === true,
                label: t(`${labelTranslationPrefix}.choiceYes`),
                value: "true",
              },
              {
                checked:
                  typeof question.inputProps.value === "boolean" &&
                  question.inputProps.value === false,
                label: t(`${labelTranslationPrefix}.choiceNo`),
                value: "false",
              },
            ]}
            type="radio"
            label={t(question.label)}
          />
        );
      }

      return arr;
    },
    [] as React.JSX.Element[]
  );

  return questionList;
};

export default SelfInsuredQuestionList;
